import React, { useState, useEffect } from 'react';

export default function MobileNavigation() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    setIsLoggedIn(document.body.classList.contains('isLoggedIn'));
  }, []);

  const navItems = [
    { id: 1, img: "/images/slot-casino-mobile-icon.webp", alt: "Online Slot", span: "Casino", navp: "Slot", href: '/slots/jili/', hidden: false },
    { id: 2, img: "/images/live-dealer-mobile-icon.webp", alt: "Live Dealer", span: "Dealer", navp: "Live", href: '/live-casino/', hidden: false },
    { id: 3, img: "/images/livedealer/sports-book-mobile-icon.webp", alt: "Sportsbook", span: "Betting", navp: "Sports", href: '/sportsbook/', hidden: false },
    { id: 4, img: "/images/promo-mobile-icon.webp", alt: "Hot Deals Promotions", span: "Deal", navp: "Promo", href: '/promotion/', hidden: false },
    { id: 5, img: "/images/recent-games-mobile-icon.webp", alt: "Recent Games", span: "Games", navp: "Recent", href: "/account/dashboard/", hidden: true },
  ];

  const listItems = navItems.map((data) => {
    if (data.hidden === true && !isLoggedIn) {
      return null; // Don't render this item if user is not logged in
    }
    return (
      <li key={data.id}>
        <a href={data.href} className='mobileNav'>
          <img src={data.img} alt={data.alt} />
          <p>{data.navp}</p>
          <p className='navSpan'>{data.span}</p>
        </a>
      </li>
    );
  });

  return (
    <section className='mobileFooterNav'>
      <ul>
        {listItems}
      </ul>
    </section>
  );
}
