import React from "react";

function PaymentMethod() {
  return (
    <>
      <div className="payment-menthod-container">
        <img src="/images/gcash-maya.webp" alt="qrph payment method"/>
        <img src="/images/dragonpay.webp" alt="dragonpay at sg8"/>
        <img src="/images/fortunepay.webp" alt="fortunepay easy payment"/>
      </div>
    </>
  );
}

export default PaymentMethod;
