// src/LiveChat.js
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faCommentSms } from "@fortawesome/free-solid-svg-icons";

const LiveChat = () => {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div>
      <div className="icon-wrapper">
        <div className="icon" onClick={togglePopup}>
          <FontAwesomeIcon icon={faCommentSms} />
        </div>
        <a href="/faq/">
          <div className="tooltip">How can we help you?</div>
        </a>
      </div>
      {showPopup && (
        <div className="overlay-sms">
          <div className="popup">
            <div className="popup-content">
              <span className="close" onClick={togglePopup}>
                <FontAwesomeIcon icon={faXmark} />
              </span>
              <section>
                <div className="footer-flexbox">
                  <p className="footer-headline-text">Contact Information</p>
                  <div className="contact-section">
                    <a href="tel:+639186531060">
                      <img
                        src="/images/phone.webp"
                        title="Connect with us"
                        alt="Reach out via phone or email on sg8.casino!"
                      />
                    </a>
                    <a
                      href="https://telegram.me/SG8_Casino"
                      target="_new"
                      rel="noopener"
                    >
                      <img
                        src="/images/telegram.webp"
                        title="Connect with us"
                        alt="Reach out via telegram on sg8.casino!"
                      />
                    </a>
                    <a
                      href="https://wa.me/639186531060"
                      target="_new"
                      rel="noopener"
                    >
                      <img
                        src="/images/whatsapp.webp"
                        title="Connect with us"
                        alt="Reach out via whatsapp on sg8.casino!"
                      />
                    </a>
                    <a
                      href="viber://chat?number=639186531060"
                      target="_new"
                      rel="noopener"
                    >
                      <img
                        src="/images/viber.webp"
                        title="Connect with us"
                        alt="Reach out via viber on sg8.casino!"
                      />
                    </a>
                  </div>
                </div>
                <div className="footer-flexbox">
                  <p className="footer-headline-text social-media">
                    Social Media
                  </p>
                  <div className="contact-section ">
                    <a
                      href="https://www.tiktok.com/@sg8_official"
                      target="_new"
                      rel="noopener"
                    >
                      <img
                        src="/images/icons/tiktok.webp"
                        title="Connect with us"
                        alt="Reach out via twitter on sg8.casino!"
                      />
                    </a>
                    <a
                      href="https://www.facebook.com/sg8casino/"
                      target="_new"
                      rel="noopener"
                    >
                      <img
                        src="/images/icons/facebook.webp"
                        title="Connect with us"
                        alt="Reach out via Facebook on sg8.casino!"
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/sg8casino/"
                      target="_new"
                      rel="noopener"
                    >
                      <img
                        src="/images/icons/instagram-2.webp"
                        title="Connect with us"
                        alt="Reach out via Instagram on sg8.casino!"
                      />
                    </a>
                    <a
                      href="https://twitter.com/SG8Official_PH"
                      target="_new"
                      rel="noopener"
                    >
                      <img
                        src="/images/icons/twitter.webp"
                        title="Connect with us"
                        alt="Reach out via Twitter on sg8.casino!"
                      />
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UClWcUi9HjzqY18a69SjSliw"
                      target="_new"
                      rel="noopener"
                    >
                      <img
                        src="/images/icons/youtube.webp"
                        title="Connect with us"
                        alt="Reach out via Youtube on sg8.casino!"
                      />
                    </a>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LiveChat;
