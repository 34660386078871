import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReactDOM from "react-dom/client";
import App from "./App";
import Promotion from "./pages/Promotion";
import LiveCasino from "./pages/LiveCasino";
import Sports from "./pages/Sports";
import Dashboard from "./pages/Dashboard";
import Deposit from "./pages/Deposit";
import Withdraw from "./pages/Withdraw";
import RequestReport from "./pages/RequestReport";
import GameReport from "./pages/GameReport";
import Verification from "./api/Verification";
import Vip from "./pages/Vip";
import Rtg from "./pages/slots/RTG";
import Spadegaming from "./pages/slots/Spadegaming";
import Bng from "./pages/slots/Bng";
import Jili from "./pages/slots/Jili";
import Playson from "./pages/slots/Playson";
import Netent from "./pages/slots/Netent";
import Redtiger from "./pages/slots/Redtiger";
import Nextspin from "./pages/slots/Nextspin";
import Nlc from "./pages/slots/Nlc";
import Pgsoft from "./pages/slots/Pgsoft";
import Jdb from "./pages/slots/Jdb";
import Cq9 from "./pages/slots/Cq9";
import Playstar from "./pages/slots/Playstar";
import Wazdan from "./pages/slots/Wazdan";
import Btg from "./pages/slots/Btg";
import Tpg from "./pages/slots/Tpg";
import Fachai from "./pages/slots/Fachai";
import Ygg from "./pages/slots/Ygg";
import Elbet from "./pages/slots/Elbet";
import Habanero from "./pages/slots/Habanero";
import PromotionPage from "./pages/promotion/DepositBonus";
import PaydayBonus from "./pages/promotion/PaydayBonus";
import BirthdayBonus from "./pages/promotion/BirthdayBonus";
import ReferralBonus from "./pages/promotion/ReferralBonus";
import CashBackBonus from "./pages/promotion/CashBackBonus";
import PageNotFound from "./pages/PageNotFound";
import AboutUs from "./pages/footer/AboutUs";
import "./style/style.css";
import "./style/accountStyle.css";
import Header from "./Header";
import Footer from "./Footer";
import Logout from "./snippets/LogoutButton";
import Faq from "./pages/footer/Faq";
import PrivacyAndPolicy from "./pages/footer/PrivacyAndPolicy";
import TermsAndCondition from "./pages/footer/TermsAndCondition";
import ResponsibleGaming from "./pages/footer/ResponsibleGaming";
import SelfExclusion from "./pages/footer/SelfExclusion";
import Slots from "./pages/slots/Slots";
import LiveChat from "./snippets/LiveChat";
import Novo from "./Novo";
import FloatingPromotion from "./snippets/FloatingPromotion";
import Download from "./pages/Download";

export const routes = [
  { path: "/", element: <App /> },
  { path: "/promotion/", element: <Promotion /> },
  { path: "/live-casino/", element: <LiveCasino /> },
  { path: "/sportsbook/", element: <Sports /> },
  { path: "/account/dashboard/", element: <Dashboard /> },
  { path: "/account/deposit/", element: <Deposit /> },
  { path: "/account/withdraw/", element: <Withdraw /> },
  { path: "/account/request-report/", element: <RequestReport /> },
  { path: "/account/game-report/", element: <GameReport /> },
  { path: "account/verification/", element: <Verification /> },
  { path: "/slots/", element: <Slots /> },
  { path: "/slots/spadegaming/", element: <Spadegaming /> },
  { path: "/slots/rtg/", element: <Rtg /> },
  { path: "/slots/bng/", element: <Bng /> },
  { path: "/slots/jili/", element: <Jili /> },
  { path: "/slots/playson/", element: <Playson /> },
  { path: "/slots/netent/", element: <Netent /> },
  { path: "/slots/redtiger/", element: <Redtiger /> },
  { path: "/slots/nextspin/", element: <Nextspin /> },
  { path: "/slots/nlc/", element: <Nlc /> },
  { path: "/slots/pgsoft/", element: <Pgsoft /> },
  { path: "/slots/jdb/", element: <Jdb /> },
  { path: "/slots/cq9/", element: <Cq9 /> },
  { path: "/slots/playstar/", element: <Playstar /> },
  { path: "/slots/wazdan/", element: <Wazdan /> },
  { path: "/slots/btg/", element: <Btg /> },
  { path: "/slots/tpg/", element: <Tpg /> },
  { path: "slots/fachai/", element: <Fachai /> },
  { path: "/slots/ygg/", element: <Ygg /> },
  { path: "/slots/elbet/", element: <Elbet /> },
  { path: "/slots/habanero/", element: <Habanero /> },
  { path: "/vip", element: <Vip /> },
  { path: "/promotion/deposit-bonus/", element: <PromotionPage /> },
  { path: "/promotion/payday-bonus/", element: <PaydayBonus /> },
  { path: "/promotion/birthday-bonus/", element: <BirthdayBonus /> },
  { path: "/promotion/referral-bonus/", element: <ReferralBonus /> },
  { path: "/promotion/cash-back-bonus/", element: <CashBackBonus /> },
  { path: "*", element: <PageNotFound /> },
  { path: "/about-us/", element: <AboutUs /> },
  { path: "/faq/", element: <Faq /> },
  { path: "/privacy-policy/", element: <PrivacyAndPolicy /> },
  { path: "/terms-and-condition/", element: <TermsAndCondition /> },
  { path: "/responsible-gaming/", element: <ResponsibleGaming /> },
  { path: "/self-exclusion/", element: <SelfExclusion /> },
  { path: "/novo/", element: <Novo /> },
  { path: "/download/", element: <Download /> }
];

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Header />
    <div id="content" className="site-content">
      <div id="primary" className="content-area" style={{backgroundImage: 'url("/images/mainbg.webp")', width: '100%', backgroundSize: '100%'}}>
        <main id="main" className="site-main">
          <Logout />
          <LiveChat/>
          <FloatingPromotion/>
          <Router>
            <Routes>
              {routes.map((route, index) => (
                <Route key={index} path={route.path} element={route.element} />
              ))}
            </Routes>
          </Router>
        </main>
      </div>
    </div>
    <Footer />
  </>
);