import React, { useState, useEffect } from "react";
import {apk} from "../Footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const settings = {
  dots: true,
  infinite: true,
  speed: 200,
  fade: true,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: false,
  centerPadding: "0",
  customPaging: (i) => (
    <button
      style={{
        width: "20px",
        height: "1px",
        padding: "2px",
        backgroundColor: "#ffffff65",
      }}
    />
  ),
  dotsClass: "slick-dots custom-dots",
  responsive: [
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        fade: true,
        centerPadding: "10px",
      },
    },
    {
      breakpoint: 840,
      settings: {
        dots: false,
      },
    },
  ],
};

const MainSlider = ({ sliderCurrentPage, bannerCurrentPage }) => {
  const slides = [
    {
      id: "Download",
      src: "/images/download/apkbannerv1.webp",
      mobileSrc: "/images/download/mobile.jpg",
      alt: "SG8 Casino Filipino | Ultimate Gaming Experience is on the app now!",
      description: "Download the new SG8 Casino Filipino mobile app for the ultimate gaming experience",
      buttonText: "DOWNLOAD",
      href: apk,
      position: "left",
      hideContent: true,
      showOnPages: ["all"],
      disableOnPages: [],
      overlay: false,
      hideButton: false,
      textColor: "#fff",
    },
    {
      id: "CashBack",
      src: "/images/sg8-cashback-6.webp",
      mobileSrc: "/images/cashback-at-sg8-mobile.webp",
      alt: "CashBack up to 6%",
      description: "Get Weekly CashBack at SG8 Casino",
      buttonText: "LEARN MORE",
      href: "/promotion/cash-back-bonus/",
      position: "left",
      hideContent: false,
      showOnPages: ["all"],
      disableOnPages: [],
      overlay: false,
      hideButton: false,
      textColor: "#fff",
    },
    {
      id: "ReferralBonus",
      src: "/images/refer-a-friend-at-sg8.webp",
      mobileSrc: "/images/refer-a-friend-at-sg8-mobile.webp",
      alt: "Referral Bonus up to 10%",
      description: "Refer a Friend and Earn Credits up to PHP 500",
      buttonText: "LEARN MORE",
      href: "/promotion/referral-bonus/",
      position: "left",
      hideContent: false,
      showOnPages: ["all"],
      disableOnPages: [],
      overlay: false,
      hideButton: false,
      textColor: "#fff",
    },
    {
      id: "BirthdayBonus",
      src: "/images/sg8-birthday-bonus-special.webp",
      mobileSrc: "/images/sg8-birthday-bonus-special-mobile.webp",
      alt: "Exclusive Birthday Bonus",
      description: "Enjoy your special day with SG8 Casino's exclusive birthday bonus",
      buttonText: "LEARN MORE",
      href: "/promotion/birthday-bonus/",
      position: "left",
      hideContent: true,
      showOnPages: ["all"],
      disableOnPages: [],
      overlay: false,
      hideButton: false,
      textColor: "#fff",
    },
    {
      id: "PaydayBonus",
      src: "/images/promotion/payday-bonus-at-sg8.webp.webp",
      mobileSrc: "/images/promotion/payday-bonus-at-sg8-mobile.webp",
      alt: "Payday Bonus | Extra Rewards on Payday",
      description: "Enjoy extra rewards and boost your gaming experience every payday.",
      buttonText: "LEARN MORE",
      href: "/promotion/payday-bonus/",
      position: "left",
      hideContent: true,
      showOnPages: ["all"],
      disableOnPages: [],
      overlay: false,
      hideButton: false,
      textColor: "#fff",
    },
    {
      id: "DepositBonus",
      src: "/images/promotion/deposit-bonus-at-sg8.webp",
      mobileSrc: "/images/promotion/deposit-bonus-at-sg8-mobile.webp",
      alt: "SG8 Casino Deposit Bonus | Boost Up to 150% Bonus",
      description: "Get more value for your money and enhance your gaming experience.",
      buttonText: "LEARN MORE",
      href: "/promotion/deposit-bonus/",
      position: "left",
      hideContent: true,
      showOnPages: ["all"],
      disableOnPages: [],
      overlay: false,
      hideButton: false,
      textColor: "#fff",
    },
    {
      id: "RTG",
      src: "/images/slots/hero/rtg-at-sg8.webp",
      mobileSrc: "/images/slots/hero/rtg-at-sg8-mobile.webp",
      alt: "RealTime Gaming",
      description:"RTG games offer top-quality online casino entertainment with a wide range of slots, table games, and specialty games. Available",
      buttonText: "Available Now",
      href: "/slots/rtg/",
      position: "left",
      hideContent: false,
      showOnPages: ["RTG"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
    },
    {
      id: "Spadegaming",
      src: "/images/slots/hero/spadegaming-at-sg8.webp",
      mobileSrc: "/images/slots/hero/spadegaming-at-sg8-mobile.webp",
      alt: "Spadegaming",
      description: "Leading Online Casino Game Provider, Spadegaming delivers high-quality online casino games with a variety of slots, table games, and more.",
      buttonText: "",
      href: "/slots/spadegaming/",
      position: "right",
      hideContent: false,
      showOnPages: ["Spadegaming"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
    },
    {
      id: "JILI",
      src: "/images/slots/hero/jili-at-sg8.webp",
      mobileSrc: "/images/slots/hero/jili-at-sg8-mobile.webp",
      alt: "JILI Games",
      description: "Premier Online Casino Gaming, JILI offers top-tier online casino games, including a diverse selection of slots, table games, and specialty games.",
      buttonText: "",
      href: "/slots/jili/",
      position: "left",
      hideContent: false,
      showOnPages: ["JILI"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#000",
    },
    {
      id: "PLAYSTAR",
      src: "/images/slots/hero/playstar-at-sg8.webp",
      mobileSrc: "/images/slots/hero/playstar-at-sg8-mobile.webp",
      alt: "Playstar",
      description: "Superior Online Casino Games, Playstar provides exceptional online casino games with a wide array of slots, table games, and more. ",
      buttonText: "",
      href: "/slots/playstar/",
      position: "right",
      hideContent: false,
      showOnPages: ["PLAYSTAR"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
    },
    {
      id: "BNG",
      src: "/images/slots/hero/bng-at-sg8.webp",
      mobileSrc: "/images/slots/hero/bng-at-sg8-mobile.webp",
      alt: "BNG Games",
      description: "Top Online Casino Entertainment, BNG Games offers a premium selection of online casino games, including slots, table games, and more.",
      buttonText: "",
      href: "/slots/bng/",
      position: "right",
      hideContent: false,
      showOnPages: ["BNG"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
    },
    {
      id: "Playson",
      src: "/images/slots/hero/playson-at-sg8.webp",
      mobileSrc: "/images/slots/hero/playson-at-sg8-mobile.webp",
      alt: "Playson",
      description: "High-Quality Online Casino Games, Playson delivers a diverse range of online casino games, featuring slots, table games, and specialty games.",
      buttonText: "",
      href: "/slots/playson/",
      position: "left",
      hideContent: false,
      showOnPages: ["Playson"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
    },
    {
      id: "Netent",
      src: "/images/slots/hero/netent-at-sg8.webp",
      mobileSrc: "/images/slots/hero/netent-at-sg8-mobile.webp",
      alt: "NetEnt Better Gaming",
      description: "Premier Online Casino Gaming, NetEnt offers a top-notch collection of online casino games, including slots, table games, and live dealer options.",
      buttonText: "",
      href: "/slots/netent/",
      position: "left",
      hideContent: false,
      showOnPages: ["Netent"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
    },
    {
      id: "Redtiger",
      src: "/images/slots/hero/redtiger-at-sg8.webp",
      mobileSrc: "/images/slots/hero/redtiger-at-sg8-mobile.webp",
      alt: "RedTiger",
      description: "Leading Online Casino Games, Red Tiger provides a diverse selection of online casino games, including innovative slots and table games.",
      buttonText: "",
      href: "/slots/redtiger/",
      position: "left",
      hideContent: false,
      showOnPages: ["Redtiger"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
    },
    {
      id: "Nextspin",
      src: "/images/slots/hero/nextspin-at-sg8.webp",
      mobileSrc: "/images/slots/hero/nextspin-at-sg8-mobile.webp",
      alt: "NextSpin",
      description: "Top Online Casino Games, NextSpin delivers a premium range of online casino games, featuring exciting slots and engaging table games.",
      buttonText: "",
      href: "/slots/nextspin/",
      position: "left",
      hideContent: false,
      showOnPages: ["Nextspin"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
    },
    {
      id: "NLC",
      src: "/images/slots/hero/nlc-at-sg8.webp",
      mobileSrc: "/images/slots/hero/nlc-at-sg8-mobile.webp",
      alt: "NoLimit City",
      description: "Innovative Online Casino Games, NoLimit City offers a unique selection of online casino games, including creative slots and engaging table games.",
      buttonText: "",
      href: "/slots/nlc/",
      position: "left",
      hideContent: false,
      showOnPages: ["NLC"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
    },
    {
      id: "PGSoft",
      src: "/images/slots/hero/pgsoft-at-sg8.webp",
      mobileSrc: "/images/slots/hero/pgsoft-at-sg8-mobile.webp",
      alt: "PG Soft",
      description: "Premier Online Casino Gaming, PG Soft provides a wide range of online casino games, featuring innovative slots and table games. ",
      buttonText: "",
      href: "/slots/pgsoft/",
      position: "right",
      hideContent: false,
      showOnPages: ["PGSoft"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
    },
    {
      id: "JDB",
      src: "/images/slots/hero/jdb-at-sg8.webp",
      mobileSrc: "/images/slots/hero/jdb-at-sg8-mobile.webp",
      alt: "JDB",
      description: "Top Online Casino Games, JDB offers a diverse selection of online casino games, including engaging slots and table games. ",
      buttonText: "",
      href: "/slots/jdb/",
      position: "right",
      hideContent: false,
      showOnPages: ["JDB"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
    },
    {
      id: "CQ9",
      src: "/images/slots/hero/cq9-at-sg8.webp",
      mobileSrc: "/images/slots/hero/cq9-at-sg8-mobile.webp",
      alt: "CQ9 Gaming",
      description: "High-Quality Online Casino Games, CQ9 delivers a premium selection of online casino games, featuring innovative slots and engaging table games.",
      buttonText: "",
      href: "/slots/cq9/",
      position: "left",
      hideContent: false,
      showOnPages: ["CQ9"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
    },
    {
      id: "wazdan",
      src: "/images/slots/hero/wazdan-at-sg8.webp",
      mobileSrc: "/images/slots/hero/wazdan-at-sg8-mobile.webp",
      alt: "Wazdan",
      description: "Premier Online Casino Games, Wazdan offers a diverse array of online casino games, including dynamic slots and engaging table games.",
      buttonText: "",
      href: "/slots/wazdan/",
      position: "right",
      hideContent: false,
      showOnPages: ["wazdan"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
    },
    {
      id: "BTG",
      src: "/images/slots/hero/btg-at-sg8.webp",
      mobileSrc: "/images/slots/hero/btg-at-sg8-mobile.webp",
      alt: "Big Time Gaming",
      description: "Leading Online Casino Games, Big Time Gaming provides a top-notch selection of online casino games, including innovative slots and exciting table games.",
      buttonText: "",
      href: "/slots/btg/",
      position: "right",
      hideContent: false,
      showOnPages: ["BTG"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
    },
    {
      id: "TPG",
      src: "/images/slots/hero/tpg-at-sg8.webp",
      mobileSrc: "/images/slots/hero/tpg-at-sg8-mobile.webp",
      alt: "Triple Profit Gaming",
      description: "Premium Online Casino Experience, Triple Profit Games offers a wide range of online casino games, including engaging slots and table games.",
      buttonText: "",
      href: "/slots/tpg/",
      position: "right",
      hideContent: false,
      showOnPages: ["TPG"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
    },
    {
      id: "Fachai",
      src: "/images/slots/hero/fachai-at-sg8.webp",
      mobileSrc: "/images/slots/hero/fachai-at-sg8-mobile.webp",
      alt: "FA Chai",
      description: "Top Online Casino Games, FaChai delivers a diverse selection of online casino games, featuring exciting slots and engaging table games.",
      buttonText: "",
      href: "/slots/fachai/",
      position: "right",
      hideContent: false,
      showOnPages: ["Fachai"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
    },
    {
      id: "ygg",
      src: "/images/slots/hero/ygg-at-sg8.webp",
      mobileSrc: "/images/slots/hero/ygg-at-sg8-mobile.webp",
      alt: "YGGDrasil",
      description: "Premier Online Casino Games, Yggdrasil offers a high-quality selection of online casino games, including innovative slots and engaging table games.",
      buttonText: "",
      href: "/slots/ygg/",
      position: "right",
      hideContent: false,
      showOnPages: ["ygg"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
    },
    {
      id: "elbet",
      src: "/images/slots/hero/elbet-at-sg8.webp",
      mobileSrc: "/images/slots/hero/elbet-at-sg8-mobile.webp",
      alt: "Elbet",
      description: "Leading Online Casino Games, Elbet provides a diverse range of online casino games, featuring exciting slots and table games.",
      buttonText: "",
      href: "/slots/elbet/",
      position: "left",
      hideContent: false,
      showOnPages: ["elbet"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
    },
    {
      id: "Habanero",
      src: "/images/slots/hero/habanero-at-sg8.webp",
      mobileSrc: "/images/slots/hero/habanero-at-sg8-mobile.webp",
      alt: "Habanero",
      description: "High-Quality Online Casino Games, Habanero offers a premium selection of online casino games, including engaging slots and table games.",
      buttonText: "",
      href: "/slots/habanero/",
      position: "left",
      hideContent: false,
      showOnPages: ["Habanero"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
    },
  ];

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 840);

  const handleLinkClick = (slideData) => {
    window.location.href = slideData.href; 
  };
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 840);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getFilteredSlides = (slides, sliderCurrentPage) => {
    if (sliderCurrentPage === "CashBack") {
      const cashBackSlide = slides.find((slide) => slide.id === "CashBack");
      return cashBackSlide ? [cashBackSlide] : [];
    } else if (sliderCurrentPage === "ReferralBonus") {
      const referralSlide = slides.find((slide) => slide.id === "ReferralBonus");
      return referralSlide ? [referralSlide] : [];
    } else if (sliderCurrentPage === "BirthdayBonus") {
      const birthdaySlide = slides.find((slide) => slide.id === "BirthdayBonus");
      return birthdaySlide ? [birthdaySlide] : [];
    } else if (sliderCurrentPage === "DepositBonus") {
      const depositSlide = slides.find((slide) => slide.id === "DepositBonus");
      return depositSlide ? [depositSlide] : [];
    } else if (sliderCurrentPage === "PaydayBonus") {
      const paydaySLide = slides.find((slide) => slide.id === "PaydayBonus");
      return paydaySLide ? [paydaySLide] : [];
    } else if (sliderCurrentPage === "all") {
      return slides;
    } else {
      return slides.filter(
        (slide) =>
          slide.showOnPages.includes("all") ||
          slide.showOnPages.includes(sliderCurrentPage)
      );
    }
  };


  const filteredSlides = getFilteredSlides(slides, sliderCurrentPage);

  const activeSlideIndex = filteredSlides.findIndex(
    (slide) => slide.id === bannerCurrentPage
  );

  if (activeSlideIndex > 0) {
    const [activeSlide] = filteredSlides.splice(activeSlideIndex, 1);
    filteredSlides.unshift(activeSlide);
  }



  const renderSlide = (slide, index) => {
    const Tag = index === 0 ? "h1" : "h2";
    const imgSrc = isMobile && slide.mobileSrc ? slide.mobileSrc : slide.src;
    const isSlideInMobile = isMobile && ["JILI"].includes(slide.id);
    const textColor = { color: isSlideInMobile ? "#fff" : slide.textColor };
    const isSlideInContent =
      isMobile && ["CashBack", "ReferralBonus"].includes(slide.id);

    return (
      <a
        onClick={() => handleLinkClick(slide)}
        key={slide.id}
        className="slide-container"
        style={{ display: "block", textDecoration: "none", color: "inherit" }}
      >
        <div
          className={`hero-banner-wrapper heroBannerWrapper-${slide.position}`}
          style={textColor}
        >
          <Tag
            className={`headingTag ${index === 0 ? "active-slide" : ""} ${
              slide.hideContent || isSlideInContent ? "hidden" : ""
            }`}
          >
            {slide.alt}
          </Tag>
          {!slide.hideContent && !isSlideInContent && (
            <>
              <p className="slide-description">{slide.description}</p>
              {!slide.hideButton && (
                <a onClick={() => handleLinkClick(slide)} className="heroBtn">
                  {slide.buttonText}
                </a>
              )}
            </>
          )}
        </div>
        {isMobile && slide.overlay && <div className="overlay"></div>}
        <img src={imgSrc} alt={slide.alt} className="slide-image" />
      </a>
    );
  };

  return (
    <div className="sg8-hero-banner">
      <Slider {...settings}>
        {filteredSlides.map((slide, index) => renderSlide(slide, index))}
      </Slider>
    </div>
  );
};

export default MainSlider;
