import React from "react";
import { Helmet } from "react-helmet";

export default function Download() {
  const handleLinkClick = (url) => {
    window.location.href = url; 
  };
  return <>
    <Helmet>
        <meta charSet="utf-8" />
            <title>
              Unlock the full experience - Download our App now!
            </title>{" "}
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
    </Helmet>

    <section className="download">
        <div className="page-headlines-container">
            <div className="pageHeaderText">
              <h1>Download App</h1>
            </div>
        </div>
        <div>
          <a onClick={() => handleLinkClick('/images/download/sg8.casino.apk')} >
              <div className="promotionBanner">
                <img src="/images/download/apkbannerv1.webp" alt="" />
              </div>
              <div className="promotionBannerMobile">
                <img src="/images/download/apkbanner-mbl.webp" alt="" />
              </div>
              <div className="downloadBtn">
                <img src="/images/download/download.png" alt="" />
              </div>
          </a>
        </div>
    </section>
  </>;
}
