import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";

export default function AboutUs() {
    const [showRegisterForm, setShowRegisterForm] = useState(false);
    const contact = "tel:+09171042422";

    const handleRegisterClick = () => {
        setShowRegisterForm(true);
    };

  return <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>SG8 Casino | Ultimage Gaming Experience 2024</title> 
                <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
                <meta name="description" content="Welcome to SG8 Casino, your premier online gaming destination for 2024. Enjoy top-quality games, exciting bonuses, and a safe, fair gaming environment!"/>
                <link rel="canonical" href="https://new.sg8.casino/about-us/" />
            </Helmet>
  <section className="aboutUs">
    <div className="page-headlines-container">
        <div className="page-h2-text-style">
            <h1>About Us</h1> <span>We Enabled, Trusted. Regulated</span>
        </div>
    </div>
    <p>SG8 prides itself in being a PAGCOR licensed Online Casino and Sportsbook, offering a wide variety of gaming products such as Online Slots Casinos, Sportsbooks, and Fishing Games. We commit ourselves to providing our players with a nonpareil array of entertainment solutions of only the finest quality.</p>
    <p>We curate the ultimate gaming experience for our players through the intertwining of elements of innovation, ambiance, state-of-the-art technology, and extraordinary services. SG8 highly values our players’ peace of mind, and thus safeguards the interests of all players by ensuring added measures are in place to create a safe and regulated environment in line with the regulations set forth by PAGCOR, for all.</p>
    <p>Thank you for choosing SG8 as your preferred gaming platform. We hope that you will enjoy an experience like no other.</p>
    <button className="yellow-btn" onClick={handleRegisterClick} style={{maxWidth: "320px", width: "100%"}}>Register To Play</button>
  </section>
  <section className="contactUs">
  <div className="page-headlines-container">
        <div className="page-h2-text-style">
            <h1>Contact Us</h1> 
        </div>
    </div>
    <p>You can chat with our friendly Customer Support Representatives via our 24/7 Live Chat function by clicking on the chat icon at the bottom right of the page. Alternatively, you may contact us via the following options listed below.</p>
    <ul className="contactUsNum">
        <li><a href={contact}><img src="/images/icons/contact.svg" alt="" /><span>Phone</span></a></li>
        <li><a href={contact}><img src="/images/icons/whatsapp.svg" alt="" /><span>Whatsapp</span></a></li>
        <li><a href={contact}><img src="/images/icons/viber.svg" alt="" /><span>Viber</span></a></li>
        <li><a href={contact}><img src="/images/icons/telegram.svg" alt="" /><span>Telegram</span></a></li>
        <li><a href={contact}><img src="/images/icons/email.svg" alt="" /><span>Email</span></a></li>
    </ul>
    
  </section>

  </>;
}
