import React from "react";
import { Helmet } from "react-helmet";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
export default function ResponsibleGaming() {
  return <>
  <Helmet>
                <meta charSet="utf-8" />
                <title>SG8 Casino Responsible Gaming | Play Safe 2024</title> 
                <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
                <meta name="description" content="Discover SG8 Casino's commitment to responsible gaming in 2024. Learn tips for safe play, set limits. Play responsibly with us."/>
                <link rel="canonical" href="https://new.sg8.casino/responsible-gaming/" />
            </Helmet>
<section className="privacyPolicy">
    <div className="page-headlines-container">
        <div className="pageHeaderText">
            <h1>Responsible Gaming</h1>
        </div>
    </div>
    <p>Responsible gaming is SG8’s top priority. Millions of players around the world enjoy gaming as a form of entertainment, and most find it to be a pleasant experience. However, some players may fall into the traps of addiction, affecting their personal life. In order to uphold social responsibility, we care for our customers and take a proactive approach towards combatting gaming addiction. As such, we adhere strictly to all rules and regulations set forth by The Philippine Amusement and Gaming Corporation (PAGCOR), as well as the following policies we have put in place.</p>
    <p>SG8 requires all new players to undergo a Know-Your-Customer (KYC) process in order to ensure that players are of legal playing age. In the event that we have reasonable suspicion that minors are seeking to use our services, and/or suspect a fraudulent declaration, we will investigate the issue thoroughly. Henceforth, we reserve the right to suspend any account on reasonable grounds, and to demand for any form of valid identification as evidence and proof of age from players. In addition, we do our utmost to ensure that none of our advertising, sponsorship, and/or promotional initiatives have the potential to appeal to minors in any way.</p>
    <p>In accordance with the rules and regulations set forth by PAGCOR, anyone under the age of 21 is not permitted to partake in any form of gaming. SG8 strictly prohibits anyone under the age of 21 from using services of any form on this website, to ensure that our policies are fully compliant with PAGCOR’s rules and regulations.</p>
    <p>If you find yourself exhibiting any of the tell-tale signs of gaming addiction – Feeling a sense of emptiness or loss, excessively borrowing money, committing and/or considering commiting criminal offenses for money, and so on, do not ignore the these signs as doing so will only exacerbate your gaming addiction. Stop and seek help immediately.</p>
    <p>Other tell-tale signs of gaming addiction to be wary of include:</p>
    <ol>
        <li>Avoid gaming when you are feeling agitated, stressed, and/or under pressure</li>
        <li>Avoid excessive drinking while gaming</li>
        <li>Set time restrictions and impose a betting limit for yourself if needed</li>
        <li>Take regular breaks</li>
        <li>Never borrow money with the intention of using it for gaming</li>
        <li>Do not make an effort to regain your losses</li>
        <li>Do not have the flawed perception that gaming can be an avenue for generating income</li>
        <li>Stop playing if you are no longer having fun</li>
    </ol>
  <Accordion allowZeroExpanded>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    SELF EXCLUSION
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Players may request to exclude themselves from any gaming establishments operated and/or regulated by PAGCOR, for a specified exclusion period – 6 months, 1 year, or 5 years.
                    </p>
                    <a href="https://pagcor.ph/regulatory/pdf/Self-Exclusion-Application-Form.pdf" target="_blank" className="yellow-btn" style={{width: "100%", maxWidth: "320px"}}>Self Exclusion Application Form</a>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    FAMILY EXCLUSION
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    “Players may request to exclude their family members from any gaming establishments operated and/or regulated by PAGCOR, for a specified exclusion period – 6 months, 1 year, or 5 years.”
                    </p>
                    <p>“All exclusion orders must be implemented at PAGCOR-operated and regulated gambling venues. Completed PAGCOR Exclusion Forms are to be placed and sealed in a long-sized brown envelope for submission:”</p>
                    <a href="https://pagcor.ph/regulatory/pdf/Family-Exclusion-Application-Form.pdf" className="yellow-btn" style={{width: "100%", maxWidth: "320px"}}>Family Exclusion Application Form</a>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    APPLICATION FOR EXCLUSION ORDER
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>Gaming Licensing and Development Department 12th Floor</p>
                    <p>Contact No.: <a href="tel:+7553904">755-3904</a></p>
                    <p> PAGCOR Main Corporate Office IMET BPO Tower, CBP-1A Metropolitan Park, Roxas Boulevard, Pasay City</p>
                    <a href="https://pagcor.ph/regulatory/exclusion2.php" className="yellow-btn">View Website</a>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    HELP CENTERS IN THE PHILIPPINES
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <strong>LIFE CHANGE RECOVERY CENTRE</strong>
                   <p>
                    Dr. Randy Misael S. Dellosa 105 Scout Rallos Street, Kamuning Brgy. Sacred Heart, Quezon City Telephone numbers: (02) 415-7964 / 415-6529 Website:
                   </p>
                   <div>
                        <a href="https://lifechangerecoverycenter.com/" className="yellow-btn">View Website</a>
                   </div>

                   <strong>BRIDGE OF HOPE DRUGS AND ALCOHOL REHABILITATION FOUNDATION, INC.</strong>
                   <p>
                   Headquarters 364 Aguirre Avenue, Phase 3,BF Homes, Parañaque City, Metro Manila Tel. No. (02) 622-0193 New Manila Branch 12 Orestes Lane, Mariposa Street 
                   </p>
                   <p>Tel. No.: <a href="tel:+025020600">(02) 502-0600</a> or <a href="tel:+09178560623">(0917) 856-0623</a></p>
                   <p>Email: <a href="mailto:help@bridgesofhope.ph">help@bridgesofhope.ph</a></p>
                   <p>Website:</p>
                   <div>
                        <a href="https://bridgesofhope.com.ph/" className="yellow-btn">View Website</a>
                   </div>
                    <p> Cebu Branch: 806 Ouano St., Ibabao- Estancia, Mandaue City, Cebu</p>
                    <p>Phone: <a href="tel:+0324209287">(032) 4209287</a></p>
                    <p>Mobile: <a href="tel:+09177046659">09177046659</a></p>
                    <p>GAMBLERS ANONYMOUS PHILIPPINES Phone: <a href="tel:+09760384333">09760384333</a></p>
                    <p>Mobile: <a href="tel:+09173062232">09173062232</a></p>
                    <p>Website:</p>
                    <div>
                        <a href="https://bridgesofhope.com.ph/" className="yellow-btn">View Website</a>
                   </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    PHILIPPINE AMUSEMENT & GAMING CORPORATION
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>Main Corporate Office: IMET BPO Tower, CBP-1A, Metropolitan Roxas Boulevard, Pasay City</p>
                    <p>Contact Numbers: <a href="tel:+0277553199">(02)77553199</a>, <a href="tel:+027755-3299">(02) 7755-3299</a>, <a href="tel:+0277553899">(02) 7755-3899</a></p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
  </section>
  </>;
}
