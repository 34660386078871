import React, { useState, useEffect } from "react";
import SlickHomebanner from "./snippets/MainSlider";
import PaymentMethod from "./snippets/PaymentMethod";
import FeaturedGamesList from "./snippets/FeaturedGameList";
import ProviderListHome from "./snippets/ProviderListHome";
import { launchGame, AlertModal } from "./pages/LiveCasino";
import { Helmet } from "react-helmet";
import PopUpModal from "./snippets/PopUpModal";
function App() {
  const [loading, setLoading] = useState(true);
  const sliderCurrentPage = "all";
  const bannerCurrentPage = "all";

  const [launching, setLaunching] = useState(false);
  const [currentFrame, setCurrentFrame] = useState(19);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const showAlert = (message) => {
    setAlertMessage(message);
    setAlertOpen(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 70);
  }, []);
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>SG8 Casino Filipino Ultimate Gaming Experience | PAGCOR Licensed 2024</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="Ultimate gaming experience with SG8 Casino Filipino, a PAGCOR licensed casino for 2024. Enjoy the best online slots, live casino, and sports betting. Play now!"
        />
        <link rel="canonical" href="https://new.sg8.casino/" />
      </Helmet>

      <PopUpModal />
      <AlertModal open={alertOpen} close={() => setAlertOpen(false)} message={alertMessage} />
      <div>
        <div className="main-wrapper">
          <div className="main-content-area">
            <SlickHomebanner
              sliderCurrentPage={sliderCurrentPage}
              bannerCurrentPage={bannerCurrentPage}
            />
            <div className="featured-slots-container">
              <div className="slots-headlines-container">
                <div className="h2-text-style">
                  <span>Hottest Slots</span> <h2>Spin Now!</h2>
                </div>
                <div className="ProviderListHome"></div>
                <div className="c2a-wrapper">
                  <a href="/slots/" className="yellow-btn">
                    View More
                  </a>
                </div>
              </div>
              <ProviderListHome />
              <div className="hottest-gamelist">
                <FeaturedGamesList />
              </div>
            </div>
            <div className="featured-slots-container">
              <div className="slots-headlines-container">
                <div className="h2-text-style">
                  <span>Hot Live Casino</span> <h2>Bet Now!</h2>
                </div>
                <div className="c2a-wrapper">
                  <a href="/live-casino/" className="yellow-btn">
                    View More
                  </a>
                </div>
              </div>
              <div className="hotLDGames">
                <div className="gameLD">
                  <img src="/images/evolution-baccarat.webp" alt="evolution games baccarat" />
                  <button
                    className="yellow-btn"
                    onClick={() =>
                      launchGame(
                        "",
                        "25000",
                        true,
                        "en",
                        0,
                        true,
                        setLaunching,
                        setCurrentFrame,
                        setAnimationComplete,
                        showAlert
                      )
                    }
                  >
                    Play Now
                  </button>
                </div>
                <div className="gameLD">
                  <img src="/images/evolution-crazy-time.webp" alt="Evolution Games Crazy Time" />
                  <button
                    className="yellow-btn"
                    onClick={() =>
                      launchGame(
                        "",
                        "25000",
                        true,
                        "en",
                        0,
                        true,
                        setLaunching,
                        setCurrentFrame,
                        setAnimationComplete,
                        showAlert
                      )
                    }
                  >
                    Play Now
                  </button>
                </div>
                <div className="gameLD">
                  <img
                    src="/images/evolution-baccarat-first-person.webp"
                    alt="Evolution Game First Person Baccarat"
                  />
                  <button
                    className="yellow-btn"
                    onClick={() =>
                      launchGame(
                        "",
                        "25000",
                        true,
                        "en",
                        0,
                        true,
                        setLaunching,
                        setCurrentFrame,
                        setAnimationComplete,
                        showAlert
                      )
                    }
                  >
                    Play Now
                  </button>
                </div>
                <div className="gameLD">
                  <img src="/images/sa-gaming-baccarat.webp" alt="SA Gaming on SG8 Casino" />
                  <button
                    className="yellow-btn"
                    onClick={() =>
                      launchGame(
                        "",
                        "36001",
                        true,
                        "en",
                        0,
                        true,
                        setLaunching,
                        setCurrentFrame,
                        setAnimationComplete,
                        showAlert
                      )
                    }
                  >
                    Play Now
                  </button>
                </div>
                <div className="gameLD">
                  <img src="/images/afb-casino-baccarat.webp" alt="AFB Casino Live on SG8 Casino" />
                  <button
                    className="yellow-btn"
                    onClick={() =>
                      launchGame(
                        "",
                        "74001",
                        true,
                        "en",
                        0,
                        true,
                        setLaunching,
                        setCurrentFrame,
                        setAnimationComplete,
                        showAlert
                      )
                    }
                  >
                    Play Now
                  </button>
                </div>
              </div>
            </div>
            <div className="featured-slots-container">
              <div className="service-selector">
                <div className="services-selector-box">
                  <img src="/images/slot-selector.webp" alt="Best Online Slot in the Philippines at SG8 Casino | JILI Games" />
                  <span>Online Slot</span>
                  <a href="/slots/jili/" className="yellow-btn">
                    Play Now
                  </a>
                </div>
                <div className="services-selector-box">
                  <img src="/images/ld-selector.webp" alt="Real Person Live Casino Live at SG8 Casino" />
                  <span>Live Casino</span>
                  <a href="/live-casino/" className="yellow-btn">
                    Play Now
                  </a>
                </div>
                <div className="services-selector-box">
                  <img src="/images/sprotsbook-selector.webp" alt="Live Betting Sportsbook at SG8 Casino" />
                  <span>Sportsbook</span>
                  <a href="/sportsbook/" className="yellow-btn">
                    Bet Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-content-wrapper">
          <div className="text-content-area">
            <h2>Welcome to SG8 Casino Philippines: Gaming Excellence</h2>
            <p>
              Welcome to SG8 Casino Philippines, your premier destination for
              online gaming. Dive into thrilling entertainment and lucrative
              opportunities with our diverse selection of games. Backed by
              PAGCOR's seal of approval, SG8 Casino guarantees a top-notch
              gaming experience that will keep you coming back for more. We
              prioritize your safety and security, with robust measures and
              advanced encryption technology to keep your information
              confidential. Our dedicated support team is available 24/7 to
              assist with any questions or concerns.
            </p>
            <h2>Thrill of Live Casino and Sports Betting</h2>
            <p>
              Experience the excitement of our Live Casino, powered by Evolution
              Gaming. Enjoy classic table games like Baccarat and Blackjack, or
              engage with live dealers in games like Roulette and Crazy Time.
              Our high-definition live streaming technology brings the action to
              you, no matter where you are. We cater to both casual players and
              high rollers with a wide range of betting limits. Our
              comprehensive sportsbook, powered by Pinnacle, offers a wide range
              of betting options for major league matchups and international
              tournaments. With real-time updates and live betting options, you
              won't miss a moment of the action. Our intuitive interface makes
              it easy to navigate and place your bets quickly and securely.
            </p>
            <h2>Explore Online Slots and Exclusive Promotions</h2>
            <p>
              Explore our vast selection of online slots, from classic fruit
              machines to modern video slots with cutting-edge graphics and
              innovative features. Discover exclusive titles like JILI Slot 777
              and popular games from leading providers like RTG. Our regularly
              updated game library ensures you'll always find something new and
              exciting. Take advantage of our exclusive promotions and bonuses,
              designed to enhance your gaming experience and maximize your
              chances of winning big. Whether you're a new player looking to
              boost your bankroll or a loyal customer seeking rewards, SG8
              Casino has something for everyone. Enjoy generous deposit bonuses,
              free spins, cashback offers, and more. Our VIP program offers even
              more perks and rewards. Join us today and experience the thrill of
              winning big at SG8 Casino, where the fun never stops!
            </p>
            <PaymentMethod />
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
