import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import MainSlider from "../../snippets/MainSlider";
import SlotProviderList from "../../snippets/SlotProviderList";
import SlotsFilter from "../../snippets/SlotsFilter";
import GameList from "../../snippets/GameList";
import SlotSearchList from '../../snippets/SlotSearchList';
import PaymentMethod from '../../snippets/PaymentMethod';

const pgsoftFilterOptions = [
    { value: 'all', label: 'All' },
    { value: 'featured', label: 'Featured' },
    { value: 'slots', label: 'Slots' },
    { value: 'table', label: 'Table' },
    { value: 'arcade', label: 'Arcade' },
];

function Pgsoft() {
    const [currentFilter, setCurrentFilter] = useState('all');
    const [sliderCurrentPage, setSliderCurrentPage] = useState('PGSoft');
    const [bannerCurrentPage, setBannerCurrentPage] = useState('PGSoft');
    const [searchQuery, setSearchQuery] = useState("");

    const handleFilterChange = (filter) => {
        setCurrentFilter(filter);
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>PGSoft X SG8 Casino | Top Online Slot Games 2024</title> 
                <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
                <meta name="description" content="Discover top online slot games of 2024 with PGSoft at SG8 Casino. Enjoy cutting-edge slots and exciting gameplay."/>
                <link rel="canonical" href="https://new.sg8.casino/slots/pgsoft/" />
            </Helmet>
            <div className="main-wrapper">
                <div className="main-content-area">
                    <MainSlider sliderCurrentPage={sliderCurrentPage} bannerCurrentPage={bannerCurrentPage} />
                    <SlotProviderList initialProvider="pgsoft" />
                    <div className="slot-filter-search-wrapper">
                        <div className="provider-logo-slots">
                            <img src="/images/slots/small-logo/pg-soft-logo-small.webp" alt="pg soft" style= {{width:'150px'}} />
                        </div>
                        <SlotSearchList searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
                        <div className="filter-slots">
                            <SlotsFilter filter={currentFilter} setFilter={handleFilterChange} filterOptions={pgsoftFilterOptions} />
                        </div>
                    </div>
                </div>
            </div>
            <GameList provider="pgsoft" filter={currentFilter} searchQuery={searchQuery} />

            <div className="main-content-wrapper">
            <div className="text-content-area">
              <h2>Online Slots at SG8 Casino</h2>
              <p>Experience the best online slots at SG8 Casino, where Filipino players can enjoy a secure and fair gaming environment regulated by PAGCOR. Our extensive collection of online slots offers a thrilling gaming experience from the comfort of your home. Enjoy seamless gameplay, high-quality graphics, and the chance to win big with every spin. SG8 Casino is dedicated to providing a top-tier gaming experience that keeps you entertained and engaged.</p>
              <h2>Play JILI Games, RTG Slots & More</h2>
              <p>SG8 Casino features a wide range of slots from over 20 top providers, including popular games from JILI, RTG, PG Soft, Fa Chai, and NetEnt. These providers are known for their engaging gameplay and stunning graphics, making every gaming session exciting. Explore our selection of games from these providers and many more to discover new favorites. Whether you prefer classic themes, innovative features, or high payout potentials, there's something for everyone at SG8 Casino. Our platform ensures that you have access to the latest and most popular slots in the industry.</p>
              <h2>Exclusive Promotions and Bonuses</h2>
              <p>Maximize your wins with our exclusive promotions and bonuses. SG8 Casino offers a variety of incentives designed to enhance your online slot experience, giving you more chances to win. From welcome bonuses to daily promotions and loyalty rewards, we provide opportunities to boost your gameplay and increase your chances of hitting the jackpot. Join SG8 Casino today, take advantage of our special offers, and start your online slot adventure. Your next big win is just a spin away! Enjoy the thrill of online slots with SG8 Casino's rewarding and exciting promotions.</p>
              <PaymentMethod/>
            </div>
            </div>
        </>
    );
}

export default Pgsoft;
