import React from "react";

export default function Novo() {
  return <>
    <div>
      <div className="telNovo">
        <iframe src="https://ccs1.novocloud.global/cc_plugin/index.php?token=8f3bd27432572cd74d66ae77f932a539" frameborder="0"></iframe>
      </div>
    </div>
  </>;
}
