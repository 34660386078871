import React from 'react';
import Sliders from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const ProviderListHome = () => {
    const providers = [
        { name: 'RealTime Gaming', image: '/images/slots/small-logo/rtg-logo-small.webp', link: '/slots/rtg/' },
        { name: 'Spadegaming', image: '/images/slots/small-logo/spadegaming-logo-small.webp', link: '/slots/spadegaming/' },
        { name: 'Playstar', image: '/images/slots/small-logo/playstar-logo-small.webp', link: '/slots/playstar/' },
        { name: 'BNG', image: '/images/slots/bng-logo.webp', link: '/slots/bng/' },
        { name: 'Jili', image: '/images/slots/jili-logo.webp', link: '/slots/jili/' },
        { name: 'Playson', image: '/images/slots/small-logo/playson-logo-small.webp', link: '/slots/playson/' },
        { name: 'NetEnt', image: '/images/slots/small-logo/netent-logo-small.webp', link: '/slots/netent/' },
        { name: 'No Limit City', image: '/images/slots/small-logo/no-limit-city-logo-small.webp', link: '/slots/nlc/' },
        { name: 'PG Soft', image: '/images/slots/small-logo/pg-soft-logo-small.webp', link: '/slots/pgsoft/' },
        { name: 'JDB', image: '/images/slots/small-logo/jdb-logo-small.webp', link: '/slots/jdb/' },
        { name: 'CQ9', image: '/images/slots/small-logo/cq9-logo-small.webp', link: '/slots/cq9/' },
        { name: 'Wazdan', image: '/images/slots/small-logo/wazdan-logo-small.webp', link: '/slots/wazdan/' },
        { name: 'BTG', image: '/images/slots/small-logo/btg-logo-small.webp', link: '/slots/btg/' },
        { name: 'TPG', image: '/images/slots/small-logo/tpg-logo-small.webp', link: '/slots/tpg/' },
        { name: 'Fachai', image: '/images/slots/small-logo/fachai-logo-small.webp', link: '/slots/fachai/' },
        { name: 'YGG', image: '/images/slots/small-logo/ygg-logo-small.webp', link: '/slots/ygg/' },
        { name: 'Elbet', image: '/images/slots/small-logo/elbet-logo-small.webp', link: '/slots/elbet/' },
        { name: 'Habanero', image: '/images/slots/small-logo/habanero-logo-small.webp', link: '/slots/habanero/' }
    ];

    const settingss = {
        dots: false,
        infinite: true,
        speed: 5000,
        slidesToShow: 8,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 840,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <Sliders {...settingss} className="provider-list-slider">
            {providers.map((provider, index) => (
                <div className="slide" key={index}>
                    <a href={provider.link}>
                        <img src={provider.image} alt={provider.name} />
                    </a>
                </div>
            ))}
        </Sliders>
    );
}

export default ProviderListHome;
