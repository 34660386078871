import React, { useState, useEffect } from "react";
import BackButton from "../../snippets/BackButton";
import MainSlider from "../../snippets/MainSlider";

import { Helmet } from "react-helmet";

export default function PaydayBonus() {
  const title = "Payday Bonus";
  const [sliderCurrentPage, setSliderCurrentPage] = useState("PaydayBonus");
  const [bannerCurrentPage, setBannerCurrentPage] = useState("PaydayBonus");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 70);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SG8 Casino Payday Bonus | Extra Rewards on Payday</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="Celebrate payday with SG8 Casino's special payday bonus. Enjoy extra rewards and boost your gaming experience every payday."
        />
        <link
          rel="canonical"
          href="https://new.sg8.casino/promotion/payday-bonus/"
        />
      </Helmet>
      <div className="promotionPage">
        <BackButton name={title} />
        <MainSlider
          sliderCurrentPage={sliderCurrentPage}
          bannerCurrentPage={bannerCurrentPage}
        />
        <h2>
          Payday Bonus at <span className="highlight">SG8 Casino</span>
        </h2>

        <div className="table-main">
          <table>
            <thead>
              <th>Deposit Amount</th>
              <th>Bronze</th>
              <th>Silver</th>
              <th>Gold</th>
              <th>Platinum</th>
            </thead>
            <tbody>
              <tr>
                <td>Php 500</td>
                <td>Php 50</td>
                <td>Php 50</td>
                <td>Php 50</td>
                <td>Php 50</td>
              </tr>
              <tr>
                <td>Php 1,000</td>
                <td>Php 50</td>
                <td>Php 120</td>
                <td>Php 120</td>
                <td>Php 120</td>
              </tr>
              <tr>
                <td>Php 2,000</td>
                <td>Php 50</td>
                <td>Php 120</td>
                <td>Php 300</td>
                <td>Php 300</td>
              </tr>
              <tr>
                <td>Php 3,000 and up</td>
                <td>Php 50</td>
                <td>Php 120</td>
                <td>Php 300</td>
                <td>Php 600</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h2>General Mechanics:</h2>
        <ol>
          <li>
            Promotional period lasts from the 15th day, 12PM (GMT+8) – 18th,
            12PM (GMT+8) of each month.
          </li>
          <li>
            Players are only allowed to claim the Payday Bonus once per day. The
            Payday Bonus amount will correspond to the players’ respective VIP
            tier.
          </li>
          <li>
            Deposit amount plus payday bonus has 1x turnover requirement before
            any withdrawal.
          </li>
          <li>
            VIP Tiers and requirements.
            <p>
              <strong>a.</strong> VIP Tiers will be updated at 1PM (GMT+8) on
              the 1st day of every month, based on the data of the preceding
              month.
            </p>
            <p>
              <strong>b.</strong> Meeting both requirements is necessary to
              advance to the next tier.
            </p>
          </li>
        </ol>
        <h2>Terms and conditions:</h2>
        <ol>
          <li>
            All prizes and other marketing costs shall be shouldered by the
            Service Provider – SG8.
          </li>
          <li>General SG8.Casino terms and conditions apply.</li>
          <li>
            SG8.Casino reserves the right to take back any bonuses claimed by
            players in the event that the terms and conditions of the promotion
            are found to have been breached by the player, and/or there is
            reasonable suspicion of foul play.
          </li>
          <li>
            SG8.Casino reserves the right to modify or cancel the promotion at
            any time without prior notice and subject for PAGCOR’s approval.
          </li>
        </ol>
      </div>
    </>
  );
}
