import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Popup from "reactjs-popup";

import { tokenURL, httpsLaunchURL, apiJSONURL } from "../pages/LiveCasino";

const GameList = ({ provider, filter, searchQuery }) => {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [launching, setLaunching] = useState(false);
  const [currentFrame, setCurrentFrame] = useState(19);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const totalFrames = 77;

  const imageRef = useRef(null);
  const isMobile = window.innerWidth <= 840;

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const domain = isMobile ? "&m=1" : "";
        const response = await axios.post(
          apiJSONURL,
          `cmd=getGame&p=${provider}${domain}&domain=sg8`,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        const jsonData = response.data;
        const loadedGames = Object.values(jsonData).map((data) => ({
          gameId: data[0],
          gameName: data[1],
          gameImg: data[2],
          gameDemo: data[3],
          gameCategory: data[4],
          gameTab: data[5],
        }));
        setGames(loadedGames);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch games:", error);
        setLoading(false);
      }
    };

    fetchGames();
  }, [provider, isMobile]);

  useEffect(() => {
    let interval;
    if (loading || launching) {
      interval = setInterval(() => {
        setCurrentFrame((prevFrame) => {
          if (prevFrame < totalFrames) {
            return prevFrame + 1;
          } else {
            setAnimationComplete(true);
            clearInterval(interval);
            return totalFrames;
          }
        });
      }, 30);
    }
    return () => clearInterval(interval);
  }, [loading, launching]);

  const saveRecentGame = (game) => {
    let recentGames = JSON.parse(localStorage.getItem("recentGames")) || [];
    recentGames = recentGames.filter((g) => g.gameId !== game.gameId);
    recentGames.unshift(game);
    if (recentGames.length > 5) {
      recentGames.pop();
    }
    localStorage.setItem("recentGames", JSON.stringify(recentGames));
  };

  const launchGame = async (game) => {
    if (!document.body.classList.contains("isLoggedIn")) {
      setPopupMessage("Please register and login to play the game.");
      setIsPopupOpen(true);
      return;
    }

    setLaunching(true);
    setCurrentFrame(19);
    setAnimationComplete(false);
    if (imageRef.current) {
      imageRef.current.classList.remove("pop");
    }

    try {
      const username = localStorage.getItem("username");
      const encryptedUsernameResponse = await axios.post(
        apiJSONURL,
        `cmd=getToken&u=${username}`
      );
      const encryptedUsername = encryptedUsernameResponse.data.token;

      const tokenResponse = await axios.get(
        `${tokenURL}?u=${encryptedUsername}&g=${game.gameId}&t=transfer&m=mobile`
      );
      const sessionToken = tokenResponse.data.token;

      const lang = "en";
      const mobileIndicator = isMobile ? "1" : "";
      const url = `${httpsLaunchURL}?s=${sessionToken}&l=${lang}&m=${mobileIndicator}`;
      console.log("Launching game with URL:", url);

      const launchGameAfterAnimation = () => {
        const popupWindow = window.open(
          url,
          "_blank",
          "toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=800, height=600"
        );

        if (popupWindow) {
          popupWindow.focus();
        } else {
          setPopupMessage("Please disable your pop-up blocker and try again.");
          setIsPopupOpen(true);
        }

        setLaunching(false);
        saveRecentGame(game);
      };

      if (animationComplete) {
        launchGameAfterAnimation();
      } else {
        setTimeout(() => {
          launchGameAfterAnimation();
        }, (totalFrames - currentFrame) * 30);
      }
    } catch (error) {
      console.error("Failed to launch game:", error);
      setLaunching(false);
    }
  };

  const filterGames = () => {
    return games
      .filter((game) => {
        let matchesFilter = true;
        if (filter) {
          switch (filter) {
            case "featured":
              matchesFilter = game.gameTab.includes("featured");
              break;
            case "slots":
              matchesFilter = game.gameCategory === 1;
              break;
            case "table":
              matchesFilter = game.gameCategory === 2;
              break;
            case "videopoker":
              matchesFilter = game.gameCategory === 5;
              break;
            case "arcade":
              matchesFilter = game.gameCategory === 4;
              break;
            case "jackpotslots":
              matchesFilter = game.gameCategory === 6;
              break;
            default:
              matchesFilter = true;
              break;
          }
        }

        if (searchQuery) {
          matchesFilter =
            matchesFilter &&
            game.gameName.toLowerCase().includes(searchQuery.toLowerCase());
        }

        return matchesFilter;
      })
      .sort((a, b) => a.gameName.localeCompare(b.gameName));
  };

  if (loading) {
    return (
      <section className="loadingGames">
        <img
          src="/images/sg8.gif"
          style={{ width: "200px" }}
          alt="Loading..."
        />
      </section>
    );
  }

  const handleImageLoad = (event) => {
    event.target.style.opacity = 1;
  };

  const filteredGames = filterGames();
  const isLoggedIn = document.body.classList.contains("isLoggedIn");
  return (
    <div className="gameListWrapper">
      <Popup open={isPopupOpen} onClose={() => setIsPopupOpen(false)} modal nested>
        <div className="popupError">
          <div className="content">
            {popupMessage}
          </div>
          <div className="actions">
            <button className="blue-btn" onClick={() => setIsPopupOpen(false)}>
              OK
            </button>
          </div>
        </div>
      </Popup>
      {launching && isLoggedIn && (
        <div className="overlay">
          <div className="loadingBar">
            <div className={`spinner ${animationComplete ? "pop" : ""}`}>
              <img
                ref={imageRef}
                src={`/images/frameLoading/sg8logo_${String(
                  currentFrame
                ).padStart(5, "0")}.png`}
                alt="Loading..."
              />
            </div>
          </div>
        </div>
      )}
      {filteredGames.length > 0 ? (
        filteredGames.map((game, index) => (
          <div
            key={index}
            className="game"
            onClick={() => (isMobile ? launchGame(game) : null)}
          >
            <div className="gameImg">
              <img
                alt={`${game.gameName} ${provider.toUpperCase()}`}
                src={game.gameImg}
                onLoad={handleImageLoad}
                style={{ opacity: 0, transition: "opacity 0.5s" }}
              />
            </div>
            {!isMobile && (
              <div className="gameDetails">
                <div className="gameName">{game.gameName}</div>
                <button onClick={() => launchGame(game)}>
                  Play Now
                </button>
              </div>
            )}
          </div>
        ))
      ) : (
        <section className="loadingGames">
          <div>
            <img src="/images/sg8-icon.webp" style={{ width: "60px" }} />
            <p>No games found for selected filter.</p>
          </div>
        </section>
      )}
    </div>
  );
};

export default GameList;
