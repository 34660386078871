// src/PromotionMenu.js
import React from 'react';
import Slider from 'react-slick';

const PromotionMenu = () => {
    const settings = {
        dots: false,
        fade: true,
        infinite: true,
        autoplay: true,
        speed: 200,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <div className='promotion-menu'>
            <Slider {...settings}>
                <div className='navarea-box'>
                    <div className="navarea-promotions">
                        <a href="/promotion/deposit-bonus/">
                            <img src="/images/depositbonus-with-ring.webp" alt="Deposit Bonus" />
                        </a>
                    </div>
                </div>
                <div className='navarea-box'>
                    <div className="navarea-promotions">
                        <a href="/promotion/payday-bonus/">
                            <img src="/images/payday-with-ring.png" alt="Payday Bonus" />
                        </a>
                    </div>
                </div>
            </Slider>
        </div>
    );
};

export default PromotionMenu;
