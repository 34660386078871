import MobileNavigation from "./snippets/MobileNavigation";
export const today = new Date();
export const apk = process.env.REACT_APP_APK;

function Footer() {
  const handleLinkClick = () => {
    window.location.href = apk;  // Navigate to the URL
  };

    // Get the current URL pathname
    const currentPage = window.location.pathname;
  return (
    <>
      <div id="content" className="site-content">
        <footer>
          <div className="sg8-footer-main">
            <div className="footer-c1">
              <div className="footer-flexbox">
                <p className="footer-headline-text">Responsible Gaming</p>
                <p className="footer-text">
                  This website offers gaming with risk experience. To be a user
                  of our site you must be over 21 years old. We are not
                  responsible for the violation of the local laws.
                </p>
                <div className="complianceLogo">
                  <img src="/images/pagcor.webp" className="pagcorLogo" alt="pagcor" />
                  <img src="/images/21yearsoldlogo.webp" className="gameresponsibly" alt="game responsibly" />
                </div>
              </div>
              <div className="footer-flexbox">
                <p className="footer-headline-text">SG8.Casino</p>
                <div className="quick-links-container">
                  <ul className="footer-links">
                    <a href="/about-us/">
                      <li>About Us</li>
                    </a>
                    <a href="/responsible-gaming/">
                      <li>Responsible Gaming</li>
                    </a>
                    <a href="/self-exclusion/">
                      <li>Self-Exclusion</li>
                    </a>
                    <a href="/faq/">
                      <li>FAQ</li>
                    </a>
                    <a href="/privacy-policy/">
                      <li>Privacy Policy</li>
                    </a>
                    <a href="/terms-and-condition/">
                      <li>Terms & Conditions</li>
                    </a>
                  </ul>
                  <ul className="footer-links">
                    <a href="/slots/">
                      <li>Slot Games</li>
                    </a>
                    <a href="/live-casino/">
                      <li>Live Casino</li>
                    </a>
                    <a href="/sportsbook/">
                      <li>Sportsbook</li>
                    </a>
                    <a href="/promotion/">
                      <li>Promotions</li>
                    </a>
                  </ul>
                </div>
              </div>
              <section>
                <div className="footer-flexbox">
                  <p className="footer-headline-text">Contact Information</p>
                  <div className="contact-section">
                    <a href="tel:+639186531060">
                      <img
                        src="/images/phone.webp"
                        title="Connect with us"
                        alt="Reach out via phone or email on sg8.casino!"
                      />
                    </a>
                    <a
                      href="https://telegram.me/SG8_Casino"
                      target="_new"
                      rel="noopener"
                    >
                      <img
                        src="/images/telegram.webp"
                        title="Connect with us"
                        alt="Reach out via telegram on sg8.casino!"
                      />
                    </a>
                    <a
                      href="https://wa.me/639186531060"
                      target="_new"
                      rel="noopener"
                    >
                      <img
                        src="/images/whatsapp.webp"
                        title="Connect with us"
                        alt="Reach out via whatsapp on sg8.casino!"
                      />
                    </a>
                    <a
                      href="viber://chat?number=639186531060"
                      target="_new"
                      rel="noopener"
                    >
                      <img
                        src="/images/viber.webp"
                        title="Connect with us"
                        alt="Reach out via viber on sg8.casino!"
                      />
                    </a>
                  </div>
                </div>
                <div className="footer-flexbox">
                  <p className="footer-headline-text social-media">
                    Social Media
                  </p>
                  <div className="contact-section ">
                  <a
                      href="https://www.tiktok.com/@sg8_official"
                      target="_new"
                      rel="noopener"
                    >
                      <img
                        src="/images/icons/tiktok.webp"
                        title="Connect with us"
                        alt="Reach out via twitter on sg8.casino!"
                      />
                    </a>
                    <a
                      href="https://www.facebook.com/sg8casino/"
                      target="_new"
                      rel="noopener"
                    >
                      <img
                        src="/images/icons/facebook.webp"
                        title="Connect with us"
                        alt="Reach out via Facebook on sg8.casino!"
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/sg8casino/"
                      target="_new"
                      rel="noopener"
                    >
                      <img
                        src="/images/icons/instagram-2.webp"
                        title="Connect with us"
                        alt="Reach out via Instagram on sg8.casino!"
                      />
                    </a>
                    <a
                      href="https://twitter.com/SG8Official_PH"
                      target="_new"
                      rel="noopener"
                    >
                      <img
                        src="/images/icons/twitter.webp"
                        title="Connect with us"
                        alt="Reach out via Twitter on sg8.casino!"
                      />
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UClWcUi9HjzqY18a69SjSliw"
                      target="_new"
                      rel="noopener"
                    >
                      <img
                        src="/images/icons/youtube.webp"
                        title="Connect with us"
                        alt="Reach out via Youtube on sg8.casino!"
                      />
                    </a>
                  </div>
                </div>
                {currentPage !== '/download/' && (
                <div className="footer-flexbox">
                  <p className="footer-headline-text social-media">
                    App
                  </p>
                  <div className="contact-section">
                  <a
                      onClick={() => handleLinkClick(apk)} style={{cursor: "pointer"}}
                      target="_new"
                      rel="noopener"
                    >
                      <img
                        src="/images/download/download.png"
                        title="Download App"
                        alt="Download our App now!"
                      />
                    </a>
                  </div>
                </div>
              )}
              </section>
            </div>
          </div>
          <div className="sg8-footer-bottom">
            <div className="tagline-footer">
              <p>
                © {today.getFullYear()} SG8.Casino. All rights reserved.  SG8
                Casino licensed and regulated by (PAGCOR)
              </p>
            </div>
          </div>
          <MobileNavigation />
        </footer>
      </div>
    </>
  );
}

export default Footer;
