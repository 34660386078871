import React, { useState, useEffect } from 'react';
import {apiJSONURL} from './pages/LiveCasino';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faUser } from '@fortawesome/free-solid-svg-icons';
import LoginModal from './Modal/LoginModal';
import Register from './api/Register';
import Verification from './api/Verification';

function Header() {
    const [showLoginForm, setShowLoginForm] = useState(false);
    const [showRegisterForm, setShowRegisterForm] = useState(false);
    const [userData, setUserData] = useState({});
    const [username, setUsername] = useState('');
    const [balance, setBalance] = useState(null);
    const [breakdown, setBreakdown] = useState({});
    const [isBodyLoggedIn, setIsBodyLoggedIn] = useState(false);
    const [triggerFetch, setTriggerFetch] = useState(false);
    const [isVisibleBalance, setIsVisible] = useState(false);
    const [isVisibleAccount, setIsVisibleAccount] = useState(false);

    
  const [isVisibleVerify, setIsVisiblVerify] = useState(false);

  const handleVerifyClick = () => {
    console.log('handleVerifyClick triggered');
    setIsVisiblVerify(!isVisibleVerify);
  };

  useEffect(() => {
    // Get all elements with the ID 'handleVerifyClick'
    const elements = document.querySelectorAll('#handleVerifyClick');

    // Attach the event listener to each element
    elements.forEach(element => {
      element.addEventListener('click', handleVerifyClick);
    });

    // Cleanup function to remove event listeners
    return () => {
      elements.forEach(element => {
        element.removeEventListener('click', handleVerifyClick);
      });
    };
  }, []);

  const HandleClose = () => {
    setIsVisiblVerify(false);
  };

    useEffect(() => {
        const savedUsername = localStorage.getItem('username');
        if (savedUsername) {
          setUsername(savedUsername);
        }
    }, [])
    const handleBreakdownClick = () => {
        setIsVisible(!isVisibleBalance);
        setIsVisibleAccount(false);
    };
    const handleAccountClick = () => {
        setIsVisibleAccount(!isVisibleAccount);
        setIsVisible(false);
    };
    const handleLogout = () => {
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('username');
        window.location.href = "/"; // Redirect to homepage after logout
      };
    
      useEffect(() => {
        const username = localStorage.getItem('username');
        if (username) {
            const fetchUserData = async () => {
                try {
                    const response = await fetch(`${apiJSONURL}?cmd=getBalance&u=${username}`);
                    const data = await response.json();
                    const balance = data.balance;
                    setBalance(balance); 
                    setBreakdown(data); 
                    setUserData({ u: username});
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            };
            fetchUserData();
        }
    }, [triggerFetch]); // useEffect will re-run whenever triggerFetch changes

    const handleReturnWallet = async () => {
        const username = localStorage.getItem('username'); // Fetch username from local storage
        const params = new URLSearchParams({
            cmd: 'withdrawFromAll',
            u: username
        });

        try {
            const response = await fetch(`${apiJSONURL}?${params}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            // Trigger re-fetching user data
            setTriggerFetch(prevState => !prevState);
        } catch (error) {
            // Handle error
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    const [vipLevel, setVipLevel] = useState(null);

    useEffect(() => {
        const username = localStorage.getItem('username');
        if (username) {
            const fetchVIPData = async () => {
                try {
                    const response = await fetch(`${apiJSONURL}?cmd=getVIP&u=${username}`);
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const data = await response.json();
                    setVipLevel(data.vipLevel);
                } catch (error) {
                    console.error('Error fetching VIP data:', error);
                }
            };

            fetchVIPData();
        }
    }, []);

    const getVipLevelImage = (level) => {
        switch (level) {
            case 1:
                return "/images/bronze.webp";
            case 2:
                return "/images/silver.webp";
            case 3:
                return "/images/gold.webp";
            case 4:
                return "/images/platinum.webp";
            default:
                return "/images/unknown.webp";
        }
    };

    const handleRegisterFromLoginClick = () => {
        setShowLoginForm(false);
        setShowRegisterForm(true);
    };
    
    const handleLoginFromRegisterClick = () => {
        setShowRegisterForm(false);
        setShowLoginForm(true);
    };

    const handleLoginClick = () => {
        setShowLoginForm(true);
    };

    const handleRegisterClick = () => {
        setShowRegisterForm(true);
    };

    const handleCloseClick = () => {
        setShowLoginForm(false);
        setShowRegisterForm(false);
    };

    useEffect(() => {
        setIsBodyLoggedIn(document.body.classList.contains('isLoggedIn'));
    }, []);

    return (
        <>
        
        {isVisibleVerify && 
          <div className='modal verification-container'>
            <div className='modal-container'><Verification /></div>
          </div>
        }
        {isVisibleVerify && 
            <button className='verification-close modal-close' onClick={HandleClose}>✖</button>
        }
            <div id="LoginTrigger" className={`modal ${showLoginForm ? 'show' : ''}`}>
                <div className='modal-main-container' style={{ backgroundImage: `url("/images/registration-form-bg.jpg")` }}>
                    <LoginModal />
                    <button className='modal-close' onClick={handleCloseClick}>✖</button>
                    {!showRegisterForm && <div className='HaveAccount'>Don't have an account? <a onClick={handleRegisterFromLoginClick}>Register</a></div>}
                </div>
            </div>
            <div id="RegTrigger" className={`modal ${showRegisterForm ? 'show' : ''}`}>
                <div className='modal-main-container' style={{ backgroundImage: `url("/images/registration-form-bg.jpg")` }}>
                    <Register />
                    <button className='modal-close' onClick={handleCloseClick}>✖</button>
                    {!showLoginForm && <div className='HaveAccount'>Have an account? <a className='login' onClick={handleLoginFromRegisterClick}>Login</a></div>}
                </div>
            </div>
            <header id="masthead" className="site-header has-logo has-menu">
                <div className="sticky-navigation desktop-view">
                <div className="navigation-fixed-container">
    <a href="/"><img className="website-logo" src="/images/sg8-casino-logo-1.webp" alt="official sg8 logo" /></a>
    <div className="user-information">
        <div className="userwrapper">
            <span className="userID">
                {vipLevel !== null ? (
                <a href='/vip/'>
                    <img src={getVipLevelImage(vipLevel)} alt={`VIP Level ${vipLevel}`} />
                </a>
                ) : (
                <div></div>
                )}
                {username}
            </span>
            <span className="userBalance">{balance ?? '...'}
            <button type="button" className="button" onClick={handleBreakdownClick}>
                <FontAwesomeIcon icon={faChevronDown} />
            </button></span>
            {isVisibleBalance && (<div className='balance-brakedown'>
                {Object.keys(breakdown).map(key => {
                    if (key !== 'data' && key !== 'balance') {
                        return (
                            <div key={key}>
                                {key}: <span>{breakdown[key]}</span>
                            </div>
                        );
                    } else {
                        return null; // Exclude these keys from rendering
                    }
                })}
                <button onClick={handleReturnWallet}>Return to Main Wallet</button>
            </div>)}
            
        </div>
        
        <div className="c2a-depo-withdraw">
            <a href="/account/deposit/" className="depo-btn">Deposit</a>
            <a href="/account/withdraw/" className="withdraw-btn">Withdraw</a>
        </div>
    </div>
    <div className="c2a-landingpage">
        <a onClick={handleLoginClick} className="login-btn">Login</a>
        <a onClick={handleRegisterClick} className="reg-btn">Register</a>
    </div>
    <div className="navigation-menu-list">
        <div className="primary-menu-list">
            <div className="menu" style={{ backgroundImage: `url(/images/slots-navigation.webp)` }}><a href="/slots/jili/"><span>Slots</span></a></div>
            <div className="menu" style={{ backgroundImage: `url(/images/ld-navigation.webp)` }}><a href="/live-casino/"><span>Live Dealer</span></a></div>
            <div className="menu" style={{ backgroundImage: `url(/images/sportsbook-navigation.webp)` }}><a href="/sportsbook/"><span>Sportsbook</span></a></div>
        </div>
        <div className="secondary-menu-list">
            <ul>
                <li><a href="/promotion/">Promotion</a></li>
                <li><a href="/vip/">VIP</a></li>
                {isBodyLoggedIn && (
                <li><a href="/account/request-report/">Activity</a></li>
                )}
                {isBodyLoggedIn && (
                <li><a href="/account/dashboard/">Profile</a></li>
            )}
            </ul>
        </div>
        
    </div>
</div>

                </div>

                <div className="mobile-header-container mobile-view">
                    <section>
                        <div className="mobile-website-logo">
                            <a href="/">
                                <img className="website-logo" src="/images/sg8-casino-logo-1.webp" alt="official sg8 logo" />
                            </a>
                        </div>
                        {!isBodyLoggedIn && (
                            <div className='mobile-login-reg'>
                                <a onClick={handleLoginClick} className="login-btn">Login</a>
                            </div>
                        )}
                        {isBodyLoggedIn && (
                            <div className='mobile-balance'><div>
                            <p>Balance</p>
                            <span className="userBalance">{balance ?? '...'}</span>
                        </div>
                        <div style={{ color: "white" }} onClick={handleBreakdownClick}>
                                    <button style={{background: "none"}} type="button" className="button">
                                        <FontAwesomeIcon style={{ color: '#111', fontSize: '20px', padding: "5px" }} icon={faChevronDown} />
                                    </button>
                        </div>
                        
                        {isVisibleBalance && (<div className='balance-brakedown'>
                            {Object.keys(breakdown).map(key => {
                                if (key !== 'data' && key !== 'balance') {
                                    return (
                                        <div key={key}>
                                            {key}: <span>{breakdown[key]}</span>
                                        </div>
                                    );
                                } else {
                                    return null; // Exclude these keys from rendering
                                }
                            })}
                            <button onClick={handleReturnWallet}>Return to Main Wallet</button>
                            <a href="/account/deposit/" class="depo-btn">Deposit</a>
                            <a href="/account/withdraw/" class="withdraw-btn">Withdraw</a>
                            </div>)}
                                <div style={{ color: "white" }} >
                                    <button style={{background: "none"}} type="button" className="button" onClick={handleAccountClick}>
                                        <FontAwesomeIcon style={{  fontSize: '20px', padding: "5px", color: '#fff' }} icon={faUser} />
                                    </button>
                                </div>
                                {isVisibleAccount && <div className='accountsub'>
                                <a href='/account/request-report/'>Activity</a>
                                <a href='/account/dashboard/'>Profile</a>
                                <a className='logout' onClick={handleLogout}>Logout</a>
                                </div>}
                            </div>
                        )}
                    </section>
                    {!isBodyLoggedIn && (
                    <div className='HaveAccount'>Don't have an account? <a onClick={handleRegisterFromLoginClick}>Register</a></div>
                    )}
                </div>
            </header>
        </>
    );
}
export default Header;
