import React, { useState, useEffect } from 'react';  
import Popup from 'reactjs-popup'; 

const PopUpModal = () => {

  const [open, setOpen] = useState(false);

  useEffect(() => {
    // Check if the modal has been shown before from sessionStorage
    const hasModalBeenShown = sessionStorage.getItem('modalShown');

    if (!hasModalBeenShown) {
      setOpen(true);  // Open the modal if it hasn't been shown
    }

    // Blurred effect and overflow hidden on modal state
    if (open) {
      document.body.classList.add('blurred');
      document.body.style.overflow = 'hidden';
    } else {
      document.body.classList.remove('blurred');
      document.body.style.overflow = ''; 
    }

    const handleUrlChange = () => {
      if (!sessionStorage.getItem('modalShown')) {
        setOpen(true);
      }
    };

    // Event Listener for URL changes
    window.addEventListener('popstate', handleUrlChange);
    window.addEventListener('hashchange', handleUrlChange);

    // Cleanup event listeners and styles on component unmount
    return () => {
      window.removeEventListener('popstate', handleUrlChange);
      window.removeEventListener('hashchange', handleUrlChange);
      document.body.classList.remove('blurred');
      document.body.style.overflow = ''; // Ensure overflow hidden is removed on unmount
    };
  }, [open]); 

  // Function to handle link clicks, set the modal to close and navigate to the URL
  const handleLinkClick = (url, confirmAge = false) => {
    if (confirmAge) {
      sessionStorage.setItem('modalShown', 'true');  // Store the modal shown state in sessionStorage
    }
    setOpen(false); 
    window.location.href = url;
  };

  return (
    <Popup open={open} onClose={() => setOpen(false)} modal nested closeOnDocumentClick={false}>
      {close => (
        <div className="popUpModal modalWithBlur" style={{ backgroundImage: `url('/images/registration-form-bg.jpg')` }}>
          <div className='popUpContent'>
            <div className="header">
              <img src="/images/SG8-logo.png" alt="SG8 Logo" /> 
            </div>
            <div className="content">
              <h2>AGE VERIFICATION</h2>
              <p>Please confirm that you are <strong>21 years of age</strong> or older to enter this site.</p>
              <div className='popUpBtn'>
                <button onClick={() => handleLinkClick('/', true)} className='yellow-btn'>I am 21 years of age</button>
                <button onClick={() => handleLinkClick('https://www.google.com/')} className='yellow-btn'>NO</button>  
              </div>
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
};

export default PopUpModal;
