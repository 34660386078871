import React, { useState } from "react";
import { Helmet } from "react-helmet";
import PaymentMethod from './../snippets/PaymentMethod';

export const promotionList = [
  {
    id: 3,
    title: "Birthday Bonus",
    desc: "Come and Celebrate your Birthday",
    alt: "Birthday Bonus",
    imgSmall: "/images/sg8-birthday-bonus-special-mobile.webp",
    imgLarge: "/images/sg8-birthday-bonus-special.webp",
    promoClick: "/promotion/birthday-bonus/",
  },
  {
    id: 4,
    title: "Referral Bonus",
    desc: "Referral Up to 10%",
    alt: "Referral Bonus",
    imgSmall: "/images/refer-a-friend-at-sg8-mobile.webp",
    imgLarge: "/images/refer-a-friend-at-sg8-mobile.webp",
    promoClick: "/promotion/referral-bonus/",
  },
  {
    id: 5,
    title: "Cashback Bonus",
    desc: "Offer Cashback Up to 6%",
    alt: "Cashback Bonus",
    imgSmall: "/images/cashback-at-sg8-mobile.webp",
    imgLarge: "/images/cashback-at-sg8-mobile.webp",
    promoClick: "/promotion/cash-back-bonus/",
  },
  {
    id: 1,
    title: "Deposit Bonus",
    desc: "Up to 150% bonus on 1st to 5th Deposits",
    alt: "Deposit Bonus",
    imgSmall: "/images/promotion/deposit-bonus-at-sg8-mobile.webp",
    imgLarge: "/images/promotion/deposit-bonus-at-sg8-mobile.webp",
    promoClick: "/promotion/deposit-bonus/",
  },
  {
    id: 2,
    title: "Payday Bonus",
    desc: "Payday Bonus at SG8 Casino",
    alt: "Payday Bonus",
    imgSmall: "/images/promotion/payday-bonus-at-sg8-mobile.webp",
    imgLarge: "/images/promotion/payday-bonus-at-sg8-mobile.webp",
    promoClick: "/promotion/payday-bonus/",
  },
];

function Promotion() {
  const [visibleId, setVisibleId] = useState(null);

  const listItems = promotionList.map((data) => (
    <li className="promoBox" key={data.id}>
      <img
        className="promotionBannerMobile"
        src={data.imgLarge}
        alt={data.alt}
      />
      <img className="promotionBanner" src={data.imgSmall} alt={data.alt} />
      <div className="promotionContent">
        <p className="promotion-title">{data.title}</p>
        <p className="promotion-info">{data.desc}</p>
        <div className="promolink-container">
          <a className="yellow-btn" href={data.promoClick}>
            T&C
          </a>
        </div>
      </div>
    </li>
  ));
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>
        SG8 Casino Promotions | 150% Deposit Bonus & Up to 6% Cashback
        </title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="Unlock amazing promotions at SG8 Casino with a 150% deposit bonus and up to 6% cashback. Maximize your gaming potential and start winning today!"
        />
        <link rel="canonical" href="https://new.sg8.casino/promotion/" />
      </Helmet>

      <div className="main-wrapper">
        <div className="main-content-area">
          <div className="page-headlines-container">
            <div className="pageHeaderText">
              <h1>SG8 Casino Promotions</h1>
              <p>Maximize your gaming potential and start winning today!</p>
            </div>
          </div>
          <div className="promotionContentarea">
            <ul className="promotionWrapper">{listItems}</ul>
          </div>
        </div>
      </div>

      <div className="main-content-wrapper">
            <div className="text-content-area">
              <h2>Welcome to SG8 Casino Philippines: Gaming Excellence</h2>
              <p>Welcome to SG8 Casino Philippines, your premier destination for online gaming. Dive into thrilling entertainment and lucrative opportunities with our diverse selection of games. Backed by PAGCOR's seal of approval, SG8 Casino guarantees a top-notch gaming experience that will keep you coming back for more. We prioritize your safety and security, with robust measures and advanced encryption technology to keep your information confidential. Our dedicated support team is available 24/7 to assist with any questions or concerns.</p>
              <h2>Thrill of Live Casino and Sports Betting</h2>
              <p>Experience the excitement of our Live Casino, powered by Evolution Gaming. Enjoy classic table games like Baccarat and Blackjack, or engage with live dealers in games like Roulette and Crazy Time. Our high-definition live streaming technology brings the action to you, no matter where you are. We cater to both casual players and high rollers with a wide range of betting limits. Our comprehensive sportsbook, powered by Pinnacle, offers a wide range of betting options for major league matchups and international tournaments. With real-time updates and live betting options, you won't miss a moment of the action. Our intuitive interface makes it easy to navigate and place your bets quickly and securely.</p>
              <h2>Explore Online Slots and Exclusive Promotions</h2>
              <p>Explore our vast selection of online slots, from classic fruit machines to modern video slots with cutting-edge graphics and innovative features. Discover exclusive titles like JILI Slot 777 and popular games from leading providers like RTG. Our regularly updated game library ensures you'll always find something new and exciting. Take advantage of our exclusive promotions and bonuses, designed to enhance your gaming experience and maximize your chances of winning big. Whether you're a new player looking to boost your bankroll or a loyal customer seeking rewards, SG8 Casino has something for everyone. Enjoy generous deposit bonuses, free spins, cashback offers, and more. Our VIP program offers even more perks and rewards. Join us today and experience the thrill of winning big at SG8 Casino, where the fun never stops!</p>
              <PaymentMethod/>
              <div></div>
            </div>
          </div>
    </>
  );
}

export default Promotion;
