import React from "react";
import { Helmet } from "react-helmet";

export default function SelfExclusion() {
  return <>
  <Helmet>
                <meta charSet="utf-8" />
                <title>SG8 Casino Self-Exclusion Program | Responsible Gaming 2024</title> 
                <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
                <meta name="description" content="Learn about SG8 Casino's self-exclusion program for responsible gaming in 2024. Apply now for a safer gaming experience."/>
                <link rel="canonical" href="https://new.sg8.casino/self-exclusion/" />
            </Helmet>
    <section className="selfExclusion">
        <div className="page-headlines-container">
            <div className="pageHeaderText">
                <h1>Self Exclusion</h1>
            </div>
        </div>
        <p> SG8.Casino is committed to be a responsible gaming provider by strictly adhering to every regulation set by the Philippine Amusement and Gaming Corporation (PAGCOR). As part of our social responsibility to our players, we take a proactive approach in ensuring that we provide a safe gaming experience by making sure that we offer exclusion program. If you feel like you are exhibiting signs of gambling addiction and it is in your best interest to take a break and/or permanently exclude yourself to any betting activity to control your gambling habits, you can apply yourself to be excluded to all of our services. </p>
        <h2>Here are signs of gambling addiction that you should be wary of:</h2>
        <ol>
            <li>Feeling remorseful, nervous, annoyed, and/or concerned about your gaming habits.</li>
            <li>Having trouble controlling your gaming, and/or feeling irritable when you attempt to stop.</li>
            <li>Gaming to recover lost funds, and/or having the perception that a “huge win” is just around the corner.</li>
            <li>Tremendous highs after victories and extreme lows after losses.</li>
            <li>Accumulating more debt as a result of your gaming, having delinquent payments, and/r experiencing any other financial difficulties.</li>
            <li>Gaming as a means of dealing with personal issues and/or coping with worry, despair, anger, or loneliness.</li>
            <li>Displaying a decrease in social interactions.</li>
        </ol>
        <p>By voluntarily excluding yourself, you will not be able to have access to our platform and services, as well as make any bet transaction through SG8.Casino for a minimum of six (6) months.</p>
        <h2>Application is free and irrevocable for the first six (6) months. You can apply by:</h2>
        <ol>
            <li>Filling up this Self Exclusion Form</li>
            <li>Submit the following documents:
                <ul>
                    <li>Photocopy of one (1) valid government ID card with photo</li>
                    <li>Fully accomplished RG Form 2</li>
                    <li>One (1) 2×2 photo taken within 6 months from application date</li>
                </ul>
            </li>
            <li>Applicant may request for an exclusion period of 6 months, 1 year or 5 years. After completion of the exclusion period, the self-exclusion order will be lifted automatically. A self-exclusion period for the first six (6) months is irrevocable.</li>
            <li>An extension of self-exclusion may be requested for at least six (6) months. RG Form 2 shall be accomplished and submitted again.</li>
            <li>Regardless of where application was applied, the self-exclusion order shall be enforced in all PAGCOR operated and regulated gaming facilities. Names of excluded persons shall be posted in PAGCOR’s national database of Restricted Personalities.</li>
        </ol>
        <div className="selfExclBtn">
        <a href="https://pagcor.ph/regulatory/pdf/Self-Exclusion-Application-Form.pdf" target="_blank" className="yellow-btn " style={{maxWidth: "420px", width: "100%", textAlign: "center"}}>Proceed with Self Exclusion Application</a>
        </div>
    </section>
  </>;
}
